$icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
$prodColor: #FFA726;
$jhbColor: #ff8426;
$standbyColor: #FFCA28;
$betaColor: #42A5F5;
$alphaColor: #9FA8DA;
$uatColor: #d05de4;
$testColor: #66BB6A;
$devColor: lighten(#66BB6A, 30%);
$aagTestColor: #7F93BA;
$aagUATColor: lighten(#002776, 40%);
$aagProdColor: #002776;
$shopriteProdColor: #e30a16;