@import "variables";

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.nav-item .nav-link {
    cursor: pointer;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.smaller {
    font-size: smaller;
}

.smallest {
    font-size: x-small;
}

.stage {
    &.prod,
    &.d0-prod,
    &.sasol-prod,
    &.nord-prod,
    &.dbg-prod,
    &.prod2 {
        background-color: lighten($prodColor, 10%);
    }

    &.beta,
    &.aag-beta,
    &.b2118,
    &.b2120 {
        background-color: lighten($betaColor, 10%);
    }

    &.alpha {
        background-color: $alphaColor;
    }

    &.rc,
    &.demo,
    &.pr {
        background-color: $uatColor;
    }

    &.demo-aus {
        background-color: lighten($testColor, 10%);
    }

    &.test,
    &.d0-test,
    &.d0-t2112,
    &.aag-test,
    &.nord-test,
    &.pen {
        background-color: $testColor;
    }

    &.uat,
    &.d0-uat,
    &.woolies-uat,
    &.shoprite-uat,
    &.aag-uat,
    &.dbg-uat {
        background-color: $uatColor;
    }

    &.dev,
    &.local {
        background-color: $devColor;
    }

    // &.aag-test {
    //     background-color: $aagTestColor;
    //     color: #e0e0e0;
    // }

    // &.aag-uat {
    // background-color: $aagUATColor;
    // color: #e0e0e0;
    // }

    &.shoprite,
    &.shoprite-prod {
        background-color: $shopriteProdColor;
        color: #fefefe;
    }

    &.aag,
    &.aag-prod {
        background-color: $aagProdColor;
        color: #fefefe;
    }
}

.check {
    &.s200 {
        background-color: green;
        color: whitesmoke;
    }

    &.s404,
    &.s400,
    &.s500,
    &.s502 {
        background-color: red;
        color: whitesmoke;
    }
}

.nav-logo {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin-right: 8px;
}

.logo {
    width: 100%;
}

.login-choices {
    .login-button {
        width: 350px;
        text-align: left;
    }
    .login-logo {
        height: 48px;
        object-fit: contain;
        padding-right: 10px;
    }
}

input.form-control.filter-text {
    color: green;
    font-weight: lighter;
    font-size: smaller;
}

table.table-analysis {
    font-size: smaller;
    td.cutt-off {
        div {
            // max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

div.grid-checks.form-group {
    input.form-check-input {
        margin-left: 12px !important;
    }
}
